<template>
    <div class="enjoying-memod">
        <h1>Memo'd: Discover and share life-changing ideas</h1>
        <div class="content">
            <div class="text">
                <p class="mb-2">
                    Memos help you learn 5-10X faster with thousands of ideas simply explained. And it’s 100% free.
                </p>
                <h3 class="mb-2">
                    Sign up to:
                </h3>
                <ul>
                    <li>
                        <img src="@assets/img/icons/organize.png" alt="organize">
                        Organize, save and share your favorite Memos
                    </li>

                    <li>
                        <img src="@assets/img/icons/customized.png" alt="organize">
                        Enjoy a customized feed of creators you love
                    </li>

                    <li>
                        <img src="@assets/img/icons/write.png" alt="organize">
                        Write and share your own Memos
                    </li>
                </ul>
                <nav-bar-auth />
            </div>
            <div class="authors-collage">
                <img src="@assets/img/profiles_big.png" class="img-fluid" alt="Authors Collage">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EnjoyingMemod",
    components: {
        NavBarAuth: () => import(/* webpackChunkName: "nav-bar-auth" */ "@c/organisms/nav-bar-auth")
    }
}
</script>

<style lang="scss" scoped>
.enjoying-memod {
    background-color: #202021;
    border-radius: 22px;
    border: 1px solid #3A3A3C;
    padding: 30px;

    .major {
        list-style: none;
        padding: 0;
    }

    h1 {
        font-family: 'Zilla Slab', serif;
        font-size: 36px;
        font-style: italic;
        margin-bottom: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
            p, ul {
                font-size: 18px;
            }

            p {
                margin-bottom: 0;
            }

            // ul {
            //     padding-left: 20px;
            // }
        }

        .nav-bar-auth {
            position: relative;
            width: fit-content;
            top: initial;
            right: initial;
            z-index: 9;
        }

        @media(max-width: $md) {
            flex-direction: column;
            // text-align: center;
            .nav-bar-auth {
                margin: 0 auto;
            }
        }
    }
}
</style>
